// import axios from "axios";
export default {
  name: 'LoginPage',
  data() {
    return {
      // 联系方式下拉弹窗是否处于显示状态,
      isContactPopperActive: false,
      langcode: this.$store.getters.lang,
      // 当前tab激活的值
      loginMethod: 'phone',
      // 语言列表
      languageList: [{
        value: 'en',
        label: 'English'
      }, {
        value: 'zh',
        label: '简体中文'
      }, {
        value: 'zht',
        label: '繁體中文(港澳)'
      }, {
        value: 'tw',
        label: '繁體中文(台灣)'
      },],
      // 登录表单
      loginForm: {
        phone: '', // 手机登录 手机号
        areacode: '', // 手机登录 账号
        password: '', // 手机登录 密码
        accountPassword: '', // 账号登录 密码
        peabbr: '', // 账号登录 物业实体简体
        username: '', // 账号登录 账号
        hybrid_user: '', // 第三方登录 账号
        img_code: '', // 第三放登录 图形验证码
      },
      // 第三方登录
      isshowmicrosoft: false,
      countryList: [],
      errmsg: '',
      isCNSite: false, // 是否为国内环境
      imgsrc: ''

    }
  },
  created() {
    this.switchLanguage(this.$utils.getBrowserLang())
    this.areacodedata()
    this.isCNSite = location.host.indexOf('cn.313fm.com') > -1
  },
  computed: {
    // 登录表单校验
    loginFormRules() {
      // 手机号校验
      const phoneValidator = (rule, value, cb) => {
        if (rule.required) {
          if (value) {
            // 是否选择区号
            if (!this.loginForm.areacode) {
              cb(new Error(this.$t('login.loginAreaCodeTips')))
            } else {
              cb()
            }
          } else {
            cb(new Error(this.$t('login.loginPhoneTips')))
          }
        } else {
          cb()
        }
      }
      return ({
        phone: [{ required: this.loginMethod === 'phone', validator: phoneValidator, trigger: 'blur' }],
        password: [{ required: this.loginMethod === 'phone', message: this.$t('login.loginPasswordTips'), trigger: 'blur' }],
        accountPassword: [{ required: this.loginMethod === 'account', message: this.$t('login.loginPasswordTips'), trigger: 'blur' }],
        peabbr: [{ required: this.loginMethod === 'account', message: this.$t('login.loginPeabbrTips'), trigger: 'blur' }],
        username: [{ required: this.loginMethod === 'account', message: this.$t('login.loginUserAccountTips'), trigger: 'blur' }],
        hybrid_user: [{ required: this.loginMethod === 'hybrid', message: this.$t('login.loginHybridAccountTips'), trigger: 'blur' }],
        img_code: [{ required: this.loginMethod === 'hybrid', message: this.$t('login.loginHybridImgCodeTips'), trigger: 'blur' }],
      })
    },
    // 联系方式列表
    contactList() {
      return [
        {
          id: 'sg',
          label: this.$t('login.labelSg') + ' +65 9828 3728',
          value: '6598283728'
        },
        {
          id: 'au',
          label: this.$t('login.labelAu') + ' +61 412 102 818',
          value: '61412102818'
        },
        {
          id: 'hk',
          label: this.$t('login.labelHk') + ' +852 8111 4289',
          value: '85281114289'
        },
        {
          id: 'tw',
          label: this.$t('login.labelTw') + ' +886 04 2316 8028',
          value: '8860423168028'
        },
        {
          id: 'cn',
          label: this.$t('login.labelCn') + ' +86 183 2088 6254',
          value: '8618320886254'
        },
        {
          id: 'email',
          label: 'sales@313fm.com',
          value: 'sales@313fm.com'
        },
      ]
    },
    // 当前语言
    currentLang() {
      let lang = this.langcode || 'zh'
      let matchItem = this.languageList.find(lItem => lItem.value.toLowerCase() === lang.toLowerCase())
      return matchItem
    },
    // 当前语言code，用来动态banner图片的
    currentLangCode() {
      if (this.langcode === 'tw') return 'zht'
      return this.langcode.toLowerCase()
    }
  },
  watch: {
    'loginForm.peabbr'(newVal) {
      this.loginForm.peabbr = newVal.toUpperCase()
    }
  },
  methods: {
    switchLanguage(lang) {
      this.langcode = lang
      this.$store.commit('SET_LANG', lang)
      this.$i18n.locale = this.$store.getters.lang
      this.$nextTick(() => {
        this.$refs.loginFormRef.clearValidate()
      })

    },
    // 登录方式tab点击事件
    handleLoginMethodTabClick() {
      // 切换时清空表单校验
      this.$nextTick(() => {
        this.$refs.loginFormRef.clearValidate()
      })
      // 将密码输入框改回密码状态
      // this.togglePasswordStatus = true
      this.errmsg = ''
    },
    showmicrosoft() {
      this.loginMethod = 'hybrid'
      this.$nextTick(() => {
        this.$refs.loginFormRef.clearValidate()
      })
      this.getImageCode();
      this.isshowmicrosoft = true;
      this.errmsg = ''
    },
    areacodedata() {
      this.$http.post('/common/areacode', {}, { disabledAuth: true }).then((response) => {
        let langcode = ''
        if (langcode) {
          let areacode = response.data.content.areacode.find(e => e.name == langcode)
          this.loginForm.areacode = areacode.id
        } else {
          this.loginForm.areacode = response.data.content.areacode[0].id
        }
        this.$nextTick(() => {
          this.$refs.loginFormRef.validateField('phone')
        })
        let datas = response.data.content.areacode
        this.countryList = datas
        // this.$forceUpdate()

      })
    },
    /**
     * string
     * @param {string} key 对应哪string个的 比如：home
     * @param {string,num} id 对应的id 比如：1
     */
    getBannerImg(key, id) {

      return require(`@/assets/login_banner/${key}_${this.currentLangCode}_${id}.png`)
    },
    // 联系方式下拉弹窗显隐事件
    contactVisibleChange(e) {
      this.isContactPopperActive = e
    },
    getImageCode() {
      this.$http.post('/common/genimgcode', {}, { disabledAuth: true }).then((response) => {
        if (response.data.success) {
          this.imgsrc = response.data.content.imagesrc;
          this.imgcodekey = response.data.content.key;
        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 表单返回
    backForm() {
      // 切换时清空表单校验
      this.$nextTick(() => {
        this.$refs.loginFormRef.clearValidate()
      })
      this.errmsg = ''
      this.isshowmicrosoft = false
      this.loginMethod = 'phone'
    },


    login() {
      let publicKey = this.$utils.getLoginPublicKey()
      this.$refs.loginFormRef.validate(valid => {
        if (valid) {
          // 手机登录
          if (this.loginMethod === 'phone') {
            let params = {
              phone: this.loginForm.phone,
              // eslint-disable-next-line
              password: sm2Encrypt(this.loginForm.password, publicKey, 0),
              langcode: this.langcode,
              areacode: this.loginForm.areacode,
              channel: 'pc',
            }

            this.$http.post('/v2/common/login', params, { disabledAuth: true }).then((response) => {
              let datas = response.data;

              if (datas.success) {
                let loginid = datas.content.hrid;
                // let penum = datas.content.penum;
                let isOperate = datas.content.isdataadmin == 'Y' ? 'true' : 'false';
                let ispemgr = datas.content.ispemgr == 'Y' ? 'true' : 'false';
                // sessionStorage.setItem("loginPhone", (self.phone));
                // sessionStorage.setItem("isOperate", ('true'));

                this.$store.commit('SET_SELFREGISTER', datas.content.selfregister)
                this.$store.commit('SET_ISPEMGR', ispemgr)
                this.$store.commit('SET_ISOPERATE', isOperate)

                this.$store.commit('SET_LOGINID', datas.content.hrid)
                this.$store.commit('SET_PENUM', datas.content.penum)

                // sessionStorage.setItem("isOperate", (isOperate));
                // sessionStorage.setItem("loginid", (datas.content.hrid));
                // sessionStorage.setItem("penum", (datas.content.penum));
                // sessionStorage.setItem("displayname", (datas.content.displayname));
                // sessionStorage.setItem("language", (datas.content.language));
                // 切换语言
                this.$store.commit('SET_LANG', datas.content.language)
                this.$i18n.locale = this.$store.getters.lang

                // sessionStorage.setItem("selfregister", (datas.content.selfregister));
                // sessionStorage.setItem("ispemgr", (ispemgr));
                sessionStorage.setItem("authKey", (datas.content.authKey || "")); // toekn authkey
                sessionStorage.setItem("publicKey", datas.content.publicKey);

                if (datas.content.expire) {
                  sessionStorage.setItem("expire", (datas.content.expire));
                }
                // const reqData = {
                //   hrid: loginid,
                //   pagename: 'home',
                //   pagenameStatus: true
                // };
                this.$http
                  .post('/common/getToken', {
                    hrid: loginid,
                    authKey: datas.content.authKey,
                    channel: 'PC',
                  }, { disabledAuth: true })
                  .then(({ data }) => {
                    if (data.success) {
                      // 先关闭，之后开启
                      // this.$http
                      //   .post('/common/guide', reqData, {
                      //     headers: {
                      //       'Access-Token': data.content.token
                      //     },
                      //     disabledAuth: true
                      //   })
                      //   .then(resultG => {
                      //     const dataTemp = resultG.data;

                      //     if (dataTemp.success) {
                      //       if (dataTemp.content.length > 0) {
                      //         this.$router.replace("/property_list")
                      //         return
                      //       }
                      //     }
                      //     this.$router.replace("/home")
                      //   })
                      //   .catch(() => {
                      //     this.$router.replace("/home")
                      //   });
                        this.$router.replace("/home")
                    } else {
                      this.$router.replace("/home")
                    }

                  }).catch(() => {
                    this.$router.replace("/home")
                  });


              } else if (datas.isAcctInAnotherHost) {
                this.$alert(this.$t('login.webSiteVersion', { anotherSite: this.$utils.getAnotherSite() }), this.$t('common.alertPrompt'), {
                  type: 'error',
                  center: true,
                  callback: () => {
                    window.location.href = this.$utils.getAnotherSite();
                  }
                })
              } else {
                this.errmsg = datas.message

                // self.loginInter();
              }
            })
          }
          // 账号登录
          if (this.loginMethod === 'account') {
            let params = {
              peab: this.loginForm.peabbr,
              roleaccount: this.loginForm.username,
              // eslint-disable-next-line
              password: sm2Encrypt(this.loginForm.accountPassword, publicKey, 0),
              langcode: this.langcode,
              channel: "PC",
            }

            this.$http.post('/v2/common/login', params, { disabledAuth: true }).then((response) => {
              let datas = response.data;
              if (datas.success) {
                // let loginid = datas.content.hrid;
                // let penum = datas.content.penum;
                let isOperate = datas.content.isdataadmin == 'Y' ? 'true' : 'false';
                let ispemgr = datas.content.ispemgr == 'Y' ? 'true' : 'false';
                // sessionStorage.setItem("loginPhone", (""));
                // sessionStorage.setItem("isOperate", (isOperate));
                // sessionStorage.setItem("loginid", (datas.content.hrid));
                // sessionStorage.setItem("penum", (datas.content.penum));
                // sessionStorage.setItem("displayname", (datas.content.displayname));
                // sessionStorage.setItem("language", (datas.content.language));

                this.$store.commit('SET_SELFREGISTER', datas.content.selfregister)
                this.$store.commit('SET_ISPEMGR', ispemgr)
                this.$store.commit('SET_ISOPERATE', isOperate)

                this.$store.commit('SET_LOGINID', datas.content.hrid)
                this.$store.commit('SET_PENUM', datas.content.penum)
                // 切换语言
                this.$store.commit('SET_LANG', datas.content.language)
                this.$i18n.locale = this.$store.getters.lang

                // sessionStorage.setItem("selfregister", (datas.content.selfregister));
                // sessionStorage.setItem("ispemgr", (ispemgr));
                sessionStorage.setItem("authKey", (datas.content.authKey || "")); // toekn authkey
                sessionStorage.setItem("publicKey", datas.content.publicKey);


                if (datas.content.expire) {
                  sessionStorage.setItem("expire", (datas.content.expire));
                }
                this.$router.replace("/home")
              } else if (datas.isAcctInAnotherHost) {
                this.$alert(this.$t('login.webSiteVersion', { anotherSite: this.$utils.getAnotherSite() }), this.$t('common.alertPrompt'), {
                  type: 'error',
                  center: true,
                  callback: () => {
                    window.location.href = this.$utils.getAnotherSite();
                  }
                })
              } else {
                this.errmsg = datas.message
              }
            })
          }
          // 第三方登录
          if (this.loginMethod === 'hybrid') {
            this.$http.post('/oauth2/authorize', {
              "mail": this.loginForm.hybrid_user,
              "langcode": this.langcode,
              "code": this.loginForm.img_code,
              "key": this.imgcodekey,
            }, { disabledAuth: true }).then((response) => {
              if (response.data.success) {
                this.errmsg = '';
                window.location.href = response.data.url
              } else {
                this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                  type: 'error',
                  center: true
                })
              }
            }).catch((response) => {
              this.errmsg = '';
              this.$alert(response.data, this.$t('common.alertPrompt'), {
                type: 'error',
                center: true
              })
              // showDialog(self.pageText.PROMPT, response.data, "error", "", [self.pageText.OK]);
            })
          }
        }
      })


    },
    // 注册
    register() {
      let query = this.$route.query
      if (query.from313) {
        this.$router.push({
          path: '/register',
          query
        })
      } else { 
        this.$router.push('/register')
      }
    },
    // 忘记密码
    forgot() {
      this.$router.push('/forgot')
    },
    forgotAccount() {
      this.$router.push('/forgot_accout')
    }
  }
}